<template>
  <v-main class="pa-0">
    <slot />
  </v-main>
</template>

<script>
export default {
  name: "NoHeader",
};
</script>
